import React, { useState } from 'react'
// import PropTypes from 'prop-types'


export default function TextForm(props) {
    const handleUpClick = () => {
        // console.log("Uppercase was clicked: " + text);
        let newText = text.toUpperCase();
        setText(newText)
    }

    const handleLoClick = () => {
        // console.log("Uppercase was clicked: " + text);
        let newText = text.toLowerCase();
        setText(newText)
    }

    const handleCamelizeClick = () => {
        let newText = text.split(' ')
            .map((word, index) => {
                if (index === 0) {
                    return word.toLowerCase();
                } else {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                }
            }).join('');
        setText(newText)
    }

    const handleExtraSpaces = () => {
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "))
    }

    const handleCopyClick = () =>{
        var text = document.getElementById("myBox");
        text.select();
        navigator.clipboard.writeText(text.value);
    }

    const handleClearClick = () => {
        // console.log("Uppercase was clicked: " + text);
        let newText = '';
        setText(newText)
    }

    const handleOnChange = (event) => {
        // console.log("On change");
        setText(event.target.value)
    }

    const [text, setText] = useState('Hit Clear and paste/type your text....');
    // text = "new text"; // Wrong way to change the state
    // setText("new text"); // Correct way to change the state
    return (
        <>
            <div>
                <div className="container">
                    <div className="container my-3">
                        <h1>{props.heading}</h1>
                        <div className="my-3">
                            <textarea className="form-control" value={text} onChange={handleOnChange} id="myBox" rows="7" />
                        </div>
                        <button className="btn btn-primary mx-2 mb-2" onClick={handleUpClick}>All Uppercase</button>
                        <button className="btn btn-primary mx-2 mb-2" onClick={handleLoClick}>All Lowercase</button>
                        <button className="btn btn-primary mx-2 mb-2" onClick={handleCamelizeClick}>Camelize It</button>
                        <button className="btn btn-primary mx-2 mb-2" onClick={handleExtraSpaces}>Remove Extra Spaces</button>
                        <button className="btn btn-warning mx-2" onClick={handleCopyClick}>Copy Changes</button>
                        <button className="btn btn-danger mx-2" onClick={handleClearClick}>Clear</button>
                    </div>
                </div>
            </div>
            <div className="container my-3">
                <h2>You Text Summary</h2>
                <p>{text.split(" ").length} words and {text.length} characters</p>
                <p>{0.0089 * text.split(" ").length} minutes to read</p>
                <h3>Preview:</h3>
                <p>{text.length > 0? text : "Enter Something to see here"}</p>
            </div>
        </>
    )
}
