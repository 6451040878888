// import logo from './logo.svg';
import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [mode, setMode] = useState('light');  // Whether Dark Mode is enabled or not 
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark')
      document.body.style.backgroundColor = 'grey';
      document.title = 'TextPlayer - Darkmode';
    } else {
      setMode('light')
      document.body.style.backgroundColor = 'white'
      document.title = 'TextPlayer - Lightmode';
    }
  }
  return (
    <>
      <BrowserRouter>
        <Navbar title="TextPlayer" mode={mode} toggleMode={toggleMode} />
        <Alert alert="🚧 Under development phase !" />
        <Routes>
          {/* What exact does is it doent allow partial matching:
          /users/ ---> Componet 1
          /users/home/ ---> Comp 2 */}
          <Route exact path="/" element={<TextForm heading='Enter the text to analyze below' mode={mode} />} />
          <Route exact path="/about" element={<About mode={mode} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
